@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?42qmfq');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?42qmfq#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?42qmfq') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?42qmfq') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?42qmfq##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pixelated-chevron-right {
  &:before {
    content: $icon-pixelated-chevron-right; 
  }
}
.icon-pixelated-chevron-left {
  &:before {
    content: $icon-pixelated-chevron-left; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;     
    color: #525252;
  }
}
.icon-right {
  &:before {
    content: $icon-right; 
  }
}
.icon-left {
  &:before {
    content: $icon-left; 
  }
}
.icon-new-chevron-down {
  &:before {
    content: $icon-new-chevron-down; 
  }
}
.icon-new-menu {
  &:before {
    content: $icon-new-menu; 
  }
}
.icon-shopcart {
  &:before {
    content: $icon-shopcart; 
  }
}
.icon-full-screen {
  &:before {
    content: $icon-full-screen; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-tumblr {
  &:before {
    content: $icon-tumblr; 
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest; 
  }
}
.icon-googleplus {
  &:before {
    content: $icon-googleplus; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}
.icon-vimeo {
  &:before {
    content: $icon-vimeo; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-arrow-right-indicator {
  &:before {
    content: $icon-arrow-right-indicator; 
  }
}
.icon-arrow-left-indicator {
  &:before {
    content: $icon-arrow-left-indicator; 
  }
}
.icon-arrow-down-indicator {
  &:before {
    content: $icon-arrow-down-indicator; 
  }
}
.icon-arrow-up-indicator {
  &:before {
    content: $icon-arrow-up-indicator; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-search-old {
  &:before {
    content: $icon-search-old; 
  }
}
.icon-arrow-down-indicator-open {
  &:before {
    content: $icon-arrow-down-indicator-open; 
  }
}
.icon-arrow-up-indicator-open {
  &:before {
    content: $icon-arrow-up-indicator-open; 
  }
}
.icon-arrow-right-indicator-open {
  &:before {
    content: $icon-arrow-right-indicator-open; 
  }
}
.icon-arrow-left-indicator-open {
  &:before {
    content: $icon-arrow-left-indicator-open; 
  }
}
.icon-flickr {
  &:before {
    content: $icon-flickr; 
  }
}
.icon-picasa {
  &:before {
    content: $icon-picasa; 
  }
}
.icon-wordpress {
  &:before {
    content: $icon-wordpress; 
  }
}
.icon-blogger {
  &:before {
    content: $icon-blogger; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-shopcart-old {
  &:before {
    content: $icon-shopcart-old; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-vine {
  &:before {
    content: $icon-vine; 
  }
}
.icon-account-old {
  &:before {
    content: $icon-account-old; 
  }
}
.icon-help-old {
  &:before {
    content: $icon-help-old; 
  }
}
.icon-wishlist-old {
  &:before {
    content: $icon-wishlist-old; 
  }
}
.icon-circle-with-plus {
  &:before {
    content: $icon-circle-with-plus; 
  }
}
.icon-circle-with-minus {
  &:before {
    content: $icon-circle-with-minus; 
  }
}
.icon-location-old {
  &:before {
    content: $icon-location-old; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-wanelo {
  &:before {
    content: $icon-wanelo; 
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-button-full {
  &:before {
    content: $icon-button-full; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-Exclamation-Triangle {
  &:before {
    content: $icon-Exclamation-Triangle; 
  }
}
.icon-map {
  &:before {
    content: $icon-map; 
  }
}
.icon-handset {
  &:before {
    content: $icon-handset; 
  }
}
.icon-address {
  &:before {
    content: $icon-address; 
  }
}
.icon-payment {
  &:before {
    content: $icon-payment; 
  }
}
.icon-favorite-filled {
  &:before {
    content: $icon-favorite-filled; 
  }
}
.icon-favorite {
  &:before {
    content: $icon-favorite; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-applepay {
  &:before {
    content: $icon-applepay; 
  }
}
.icon-weibo {
  &:before {
    content: $icon-weibo; 
  }
}
.icon-qq {
  &:before {
    content: $icon-qq; 
  }
}
.icon-chat {
  &:before {
    content: $icon-chat; 
  }
}
.icon-kakao {
  &:before {
    content: $icon-kakao; 
  }
}
.icon-wechat {
  &:before {
    content: $icon-wechat; 
  }
}
.icon-line {
  &:before {
    content: $icon-line; 
  }
}
.icon-Check-Circle {
  &:before {
    content: $icon-Check-Circle; 
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark; 
  }
}
.icon-add-user {
  &:before {
    content: $icon-add-user; 
  }
}
.icon-reset {
  &:before {
    content: $icon-reset; 
  }
}
.icon-camera {
  &:before {
    content: $icon-camera; 
  }
}
.icon-zoom-in {
  &:before {
    content: $icon-zoom-in; 
  }
}
.icon-zoom-out {
  &:before {
    content: $icon-zoom-out; 
  }
}
.icon-background-color {
  &:before {
    content: $icon-background-color; 
  }
}
.icon-camera1 {
  &:before {
    content: $icon-camera1; 
  }
}
.icon-crop {
  &:before {
    content: $icon-crop; 
  }
}
.icon-flip {
  &:before {
    content: $icon-flip; 
  }
}
.icon-popart {
  &:before {
    content: $icon-popart; 
  }
}
.icon-redo {
  &:before {
    content: $icon-redo; 
  }
}
.icon-rotate {
  &:before {
    content: $icon-rotate; 
  }
}
.icon-tile {
  &:before {
    content: $icon-tile; 
  }
}
.icon-tint {
  &:before {
    content: $icon-tint; 
  }
}
.icon-undo {
  &:before {
    content: $icon-undo; 
  }
}
.icon-zoom {
  &:before {
    content: $icon-zoom; 
  }
}
.icon-line-logo {
  &:before {
    content: $icon-line-logo; 
  }
}
.icon-randomizer-check {
  &:before {
    content: $icon-randomizer-check; 
  }
}
.icon-x-inside-circle {
  &:before {
    content: $icon-x-inside-circle; 
  }
}
.icon-checkmark-inside-circle {
  &:before {
    content: $icon-checkmark-inside-circle; 
  }
}
.icon-accessibility {
  &:before {
    content: $icon-accessibility; 
  }
}
.icon-Logo-Customs {
  &:before {
    content: $icon-Logo-Customs; 
  }
}
.icon-long-arrow-right {
  &:before {
    content: $icon-long-arrow-right; 
  }
}
.icon-feather-loader {
  &:before {
    content: $icon-feather-loader; 
  }
}
.icon-question {
  &:before {
    content: $icon-question; 
  }
}
.icon-gift {
  &:before {
    content: $icon-gift; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-help {
  &:before {
    content: $icon-help; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-truck {
  &:before {
    content: $icon-truck; 
  }
}
.icon-account {
  &:before {
    content: $icon-account; 
  }
}
.icon-vansfamilystar {
  &:before {
    content: $icon-vansfamilystar; 
  }
}
.icon-wishlist {
  &:before {
    content: $icon-wishlist; 
  }
}
.icon-sign-out {
  &:before {
    content: $icon-sign-out; 
  }
}
.icon-vans-finger {
  &:before {
    content: $icon-vans-finger; 
  }
}
.icon-vans-lock {
  &:before {
    content: $icon-vans-lock; 
  }
}
.icon-vans-present {
  &:before {
    content: $icon-vans-present; 
  }
}
.icon-clear-search {
  &:before {
    content: $icon-clear-search; 
  }
}
.icon-slim-search {
  &:before {
    content: $icon-slim-search; 
  }
}
.icon-close-fat {
  &:before {
    content: $icon-close-fat; 
  }
}
.icon-syte-camera {
  &:before {
    content: $icon-syte-camera; 
  }
}
.icon-gift-old {
  &:before {
    content: $icon-gift-old; 
  }
}
.icon-bin {
  &:before {
    content: $icon-bin; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-cross {
  &:before {
    content: $icon-cross; 
  }
}
.icon-sign-in {
  &:before {
    content: $icon-sign-in; 
  }
}
.icon-sign-out-old {
  &:before {
    content: $icon-sign-out-old; 
  }
}
.icon-success {
  &:before {
    content: $icon-success; 
  }
}
.icon-error {
  &:before {
    content: $icon-error; 
  }
}
.icon-sort {
  &:before {
    content: $icon-sort; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}

